class Helpers {
  static can(permissions, permissionType = 'menu', itemMenu = 'Home') {
    return permissions[permissionType]?.find((item) => item == itemMenu);
  }
  static currency_br(value) {
    if (isNaN(value)) return undefined;
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  }
}

export default Helpers;
