import React, { useEffect}  from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from '../../config/theme';
import { makeStyles } from "@material-ui/core/styles";
import { Container, TextField, Typography, MenuItem } from '@material-ui/core';
import LoadingButton from '../../components/LoadingButton.js'
import EditIcon from "@material-ui/icons/Edit";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  main: {
    textAlign: "center",
    padding: theme.spacing(3),
    "@media(max-width: 1468px)": {
      margin: "2% 15%",
    },
    margin: "2% 28%",
    "@media(max-width: 768px)": {
      margin: "2% 0%",
    },
    "& .MuiTypography-colorInherit-47": {
      color: "#fff",
    },
  },
  mainHeader: {
    textAlign: "left",
  },
  title: {
    fontFamily: "Poppins, sans-serif",
    fontWeight: 500,
    color: "#FEDF02",
    width: "45%",
    "@media(max-width: 768px)": {
      width: "100%",
    },
  },
  icons: {
    fill: "#ffe103",
    fontSize: "1.75em",
    verticalAlign: "bottom",
    marginRight: "10px",
  },
  menuList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  item: {
    borderRadius: "10px",
    width: "50%",
  },
  link: {
    textDecoration: "none",
    width: '42%',
    '@media(max-width: 1260px)': {
      width: '50%',
    },
    '@media(max-width: 600px)': {
      width: '65%',
    },
    '@media(max-width: 475px)': {
      width: '80%',
    },
    '@media(max-width: 375px)': {
      width: '95%',
    }
  },
  profileDetails:{
    marginBottom: '20px',
    "& h1":{
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      color: '#FEDF02',
    },
    "& p":{
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 'bold'
    }
  },
  form:{
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #FFE105',
    padding: '32px',
    backgroundColor: '#18181B',
    "& .Mui-disabled": {
      color: '#fff',
      backgroundColor: "transparent",
    },
  },
}));

function cpfFormat(value){
  if (!value) return ""
  return value.replace(/(\d{3})?(\d{3})?(\d{3})?(\d{2})/, "$1.$2.$3-$4")
}

function phoneFormat(value){
  if (!value) return ""
  value = value.replace(/\D/g,'')
  value = value.replace(/(\d{2})(\d)/,"($1) $2")
  value = value.replace(/(\d)(\d{4})$/,"$1-$2")
  return value
}

export default function EditProfile(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Typography variant="h5" className={classes.title}>
            <EditIcon className={classes.icons} />
            Editar perfil
          </Typography>
          <br />
        </div>
        <Container maxWidth="xs">
          <div className={classes.form}>
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Nome Social"
              value={props?.user?.social_name || ''}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Nome"
              value={props?.user?.name || ''}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="CPF"
              value={props?.user?.cpf_number || ''}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="E-mail"
              value={props?.user?.email || ''}
              InputProps={{
                disableUnderline: true
              }}
            />
            <TextField
              disabled
              variant="standard"
              fullWidth
              label="Telefone"
              value={props?.user?.phone || ''}
              InputProps={{
                disableUnderline: true
              }}
            />
          </div>
        </Container>
        <br/>
        <div className={classes.menuList}>
          <Link className={classes.link} to="/site/change_email">
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary">
              {props?.user?.email == '-' ? 'ADICIONAR E-MAIL' : 'ALTERAR E-MAIL'}
            </LoadingButton>
          </Link>
          <Link className={classes.link} to="/site/change_social_name">
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary">
                {props?.user?.social_name && props?.user?.social_name != '' ? 'ALTERAR NOME SOCIAL' : 'DEFINIR NOME SOCIAL'}
            </LoadingButton>
          </Link>
          <Link className={classes.link} to="/site/change_phone">
            <LoadingButton
            fullWidth
            variant="contained"
            color="primary">
              ALTERAR TELEFONE
            </LoadingButton>
          </Link>
          <Link className={classes.link} to="/site/change_password">
            <LoadingButton
              fullWidth
              variant="contained"
              color="primary">
                {props?.user?.fast_account ? 'DEFINIR SENHA' : 'ALTERAR SENHA'}
            </LoadingButton>
          </Link>
          <Link className={classes.link} to="/site/account_remove">
            <LoadingButton
              className={classes.submit}
              fullWidth
              variant="contained"
              color="primary">
              REMOVER CONTA
            </LoadingButton>
          </Link>
        </div>
      </div>
    </ThemeProvider>
  );
}
