import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactCodeInput from "react-verification-code-input";
import LoadingButton from '../../../components/LoadingButton'
import { TextMaskPhone } from '../../../components/TextMaskCustom';

const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'Poppins sans-serif',
    fontWeight: 500,
    alignSelf: 'start'
  },
  newAccountFooter: {
    marginTop: '20px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #FFE105',
    padding: '32px',
    backgroundColor: '#18181B',
  },
  sendSmsBtn: {
    fontSize: '12px',
    padding: '17px 5px',
    marginTop: '9px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  codeInput: {
    width: '325px !important',
    '& .styles_react-code-input__CRulA > input': {
      color: '#fff',
      backgroundColor: 'transparent',
      border: '1px solid #FFE105 !important',
      fontFamily: 'Poppins, sans-serif',
      margin: '8px',
      borderRadius: '0px'
    },
    '.styles_react-code-input__CRulA > input:focus': {
      border: '1px solid #FFE105',
      fontFamily: 'Poppins, sans-serif',
      caretColor: '#fff'
    }
  },
  smsDiv: {
    display: 'none'
  }
}));

export default function GanericSmsConfirmation(props) {
  const classes = useStyles();

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          required={true}
          key={'unconfirmed_phone'}
          id={'unconfirmed_phone'}
          name={'unconfirmed_phone'}
          label={'Telefone'}
          fullWidth={true}
          autoComplete={'unconfirmed_phone'}
          autoFocus={true}
          value={props?.user.get['unconfirmed_phone']}
          error={!!props?.errors.get.unconfirmed_phone}
          type={'phone'}
          helperText={props?.errors.get['unconfirmed_phone'] ? props?.errors.get['unconfirmed_phone'] : ''}
          onChange={(e) => props?.user.set({ ...props?.user.get, ['unconfirmed_phone']: e.target.value })}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <LoadingButton
          onClick={props?.handleBtnSendSms}
          fullWidth
          loading={props?.sendSmsLoading.get}
          variant="contained"
          color="primary"
          className={classes.sendSmsBtn}
        >
          {props?.sendSmsLabel.get}
        </LoadingButton>
      </div>
      <div className={classes.smsDiv} id="smsDiv">
        <Typography style={{ fontSize: '14px', padding: '20px 0px' }}>
          Digite o código de de 4 dígitos enviado para o seu número:
        </Typography>
        <div style={{ textAlign: '-webkit-center' }}>
          <ReactCodeInput className={classes.codeInput} onChange={e => { props?.user.set({ ...props?.user.get, ['token']: e }); props?.handleReactCodeInput(e) }} type="number" fields={4} />
          <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{props?.errors.get.token}</span>
        </div>
      </div>
    </div>
  );
}
