import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import InstantWinner from './InstantWinner';
import Typography from '@material-ui/core/Typography';
import DownArrow from '../../../images/down_arrow.png';

const useStyles = makeStyles((theme) => ({
  greenText: {
    color: '#FFE005',
    fontWeight: 600,
  },
  filters: {
    textAlign: 'center',
    marginBottom: '20px',
    marginTop: '10px',
    color: '#18181B',
    '& button': {
      border: 'none',
      backgroundColor: 'white',
      padding: '8px 20px',
      cursor: 'pointer',
      '&:first-child': {
        borderRadius: '20px 0 0 20px',
      },
      '&:last-child': {
        borderRadius: '0 20px 20px 0',
      },
    },
  },
  selected: {
    color: 'black',
    background: 'linear-gradient(90deg, #E1C600 -10.57%, #FFE105 91.15%);'
  },
  numbers: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& span': {
      padding: '2px 6px',
      margin: '5px',
      borderRadius: '14px',
      width: '82px',
    }
  },
  won: {
    backgroundColor: '#BEBEBE'
  },
  available: {
    backgroundColor: '#FFE005'
  },
  arrow: {
    marginLeft: '10px',
    verticalAlign: 'sub',
  },
}));

export default function InstantWinners({ campaign }) {
  const classes = useStyles();
  const [searchButton, setSearchButton] = useState('numbers');

  function switchTo(tab) {
    setSearchButton(tab);
  }

  const groupedNumbers = campaign.instant_prizes.reduce((acc, prize) => {
    const value = prize.prize_value;
    if (!acc[value]) {
      acc[value] = [];
    }
    acc[value] = acc[value].concat(prize.prize_ticket_numbers).flat().sort((a, b) => a - b);
    return acc;
  }, {});

  function formatValue(value) {
    if (value >= 1000) {
      return (value / 1000).toFixed(1).replace('.0', '') + ' mil';
    }
    return parseFloat(value).toString();
  }


  function padNumber(number) {
    return number.toString().padStart(campaign.number_of_digits, '0');
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          <span className={classes.greenText}>Prêmios Instantâneos</span>
        </Typography>
        <div>
          <div className={classes.filters}>
            <button onClick={() => switchTo('numbers')} className={searchButton == 'numbers' ? classes.selected : null}>Números</button>
            <button onClick={() => switchTo('winners')} className={searchButton == 'winners' ? classes.selected : null}>Ganhadores</button>
          </div>
          {
            searchButton == 'numbers' ?
              <div>
                <span style={{ color: 'white' }}>Números premiados: </span><span style={{ color: '#FFE005' }}>se achar um dos amarelos, o premio é seu!</span> <br />
                {
                  campaign.instant_winners.length > 0 &&
                  <span style={{ color: '#BDBDBD' }}>(algum sortudo já achou os cinzas)<br /></span>
                }
                <br />
                {Object.keys(groupedNumbers).map((value, index) => (
                  <div key={index}>
                    <Typography variant="h6">Prêmio de <span className={classes.greenText}>R$ {formatValue(value)}</span><img src={DownArrow} alt="seta para baixo" className={classes.arrow} width="15" height="20" /></Typography>
                    <br />
                    <div className={classes.numbers}>
                      {groupedNumbers[value].map(
                        (number, index) => {
                          return <span key={index} class={campaign.instant_winners.some(winner => winner.number == number) ? classes.won : classes.available}>{padNumber(number)}</span>
                        }
                      )}
                    </div>
                    <br />
                  </div>
                ))}
              </div>
              :
              <div>
                {campaign.instant_winners.map((instant_winner, index) => <InstantWinner key={index} index={index + 2} winner={instant_winner} campaign={campaign} />)}
              </div>
          }
        </div>
      </div>
    </ThemeProvider>
  )
}
