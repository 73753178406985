import React, { useState, useEffect } from 'react';
import Order from '../components/Order.js';
import axios from 'axios';
import ResponsiveContainer from '../components/ResponsiveContainer';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme.js';
import Typography from '@material-ui/core/Typography';
import TrophyIcon from '../../images/trophy.svg';
import { makeStyles } from '@material-ui/core/styles';
import InfoMessage from '../components/InfoMessage';

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: '620px',
  },
  mainHeader: {
    textAlign: 'left',
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    marginBottom: '20px',
    '& small': {
      fontWeight: 400,
      color: '#FFE005',
      fontSize: '70%',
      marginLeft: '10px'
    }
  },
  button: {
    color: 'white',
    borderRadius: '20px',
    border: '2px solid #B4B2B2',
    backgroundColor: 'transparent',
    padding: '8px 20px',
    cursor: 'pointer',
  },
  orders: {
    paddingTop: '30px',
    // minHeight: '600px'
  },
  info: {
    color: 'white',
    margin: '40px',
    textAlign: 'center',
    fontSize: '120%',
    fontWeight: 600
  }
}));

export default function OrdersIndex({ currentUser, influencer }) {
  const classes = useStyles();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(20);
  const [loading, setLoading] = useState(true);
  const [finalPage, setFinalPage] = useState(false);

  if (!currentUser)
    window.location.href = '/public/sign_in';

  async function fetchOrders(page) {
    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/purchase_orders/${influencer.username}/index?page=${page}&per_page=${perPage}`);
      if (response.data.length != perPage)
        setFinalPage(true);
      setOrders(orders.concat(response.data));
    } catch (error) {
      setOrders([]);
    } finally {
      setLoading(false);
    }
  }

  function loadMore() {
    fetchOrders(currentPage + 1);
    setCurrentPage(currentPage + 1);
  }

  useEffect(() => {
    fetchOrders(currentPage);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div className={classes.main}>
          <Typography variant="h5" className={classes.title}>
            <img src={TrophyIcon} height={30} className={classes.lightningIcon} />&nbsp;
            Meus títulos
          </Typography>
          <div className={classes.orders}>
            {!loading && orders.map((order) => (
              <Order key={order.id} order={order} />
            ))}
            {loading && <div className={classes.info}>Carregando...</div>}
            {!loading && orders.length == 0 && <InfoMessage>Nenhum pedido encontrado</InfoMessage>}
          </div>
          <div>
            {!finalPage && <button disabled={loading} className={classes.button} onClick={loadMore}>Carregar mais</button>}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  );
};