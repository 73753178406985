import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GenericForm from '../components/Generic/GenericForm';
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ChangeSocialName(props) {
  const classes = useStyles();
  const [socialName, setSocialname] = useState(props?.user.social_name);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function updateSocialName(e){
    setLoading(true);
    e.preventDefault();
    document.activeElement.blur();

    const formData = new FormData();
    formData.append("customer[social_name]", socialName);

    axios
      .put(`/api/v1/customers/update_social_name`, formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        toast.success("Informação atualizada");
        setTimeout(() => {
          window.location.href = "/site/change_profile";
          setLoading(false);
          }, 5000);
        })
      .catch(function (error) {
        if (error.response?.status == 422) {
          setErrors(error.response.data);
        } else {
          toast.error(`Erro ao processar solicitação.`);
        }
        setLoading(false);
      })
  }

  return (
    <div className={classes.paper}>
      <GenericForm
        title="Alterar Nome Social"
        loadingButtonLabel="ALTERAR"
        loading={loading}
        onSubmit={updateSocialName}
        backButton
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Nome Social"
          value={socialName}
          onChange={(e) => setSocialname(e.target.value)}
          error={!!errors?.social_name}
          helperText={errors["social_name"] ? errors["social_name"] : ""}
          autoComplete="social_name"
          autoFocus
        />
      </GenericForm>
    </div>
  );
}