import React, { useEffect, useState } from "react";
import InnerApp from "./InnerApp";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../config/theme";
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../public/styles/main.scss";

export default function App({
  flash,
  global_schema,
  influencer,
  current_customer
}) {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <InnerApp flash={flash} influencer={influencer} global_schema={global_schema} current_customer={current_customer} />
      </Router>
    </ThemeProvider>
  );
}
