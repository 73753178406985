import React from 'react';
import { IMaskInput } from 'react-imask';
import moment from 'moment';
import IMask from 'imask';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  const { mask, extra } = routeMask(props);

  return (
    <IMaskInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      onAccept={
        // Advance to the next field, if there is one, when mask is completed
        (value, mask) => {
          if (mask.masked.isComplete) {
            const form = mask.el.input.form;
            const index = Array.prototype.indexOf.call(form, mask.el.input);
            if (form.elements.length - 1 > index) {
              form.elements[index + 1].focus();
            }
          }
        }
      }
      mask={mask}
      {...extra}
      // showMask={false}
      // placeholderChar={"\u2000"}
    />
  );
}

function routeMask(props) {
  let mask;
  let extra;

  switch (props?.mask ? props.mask : props) {
    case 'cpf':
      mask = '000.000.000-00';
      break;
    case 'currency':
      extra = {
        mask: Number, // enable number mask
        // other options are optional with defaults below
        scale: 2, // digits after point, 0 for integers
        signed: false, // disallow negative
        thousandsSeparator: '.', // any single char
        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: ',', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix

        // additional number interval options (e.g.)
        min: 0,
      };
      break;
    case 'number':
      extra = {
        mask: Number, // enable number mask
        // other options are optional with defaults below
        scale: 0, // digits after point, 0 for integers
        signed: false, // disallow negative
        thousandsSeparator: '.', // any single char
        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: ',', // fractional delimiter
        mapToRadix: ['.'], // symbols to process as radix

        // additional number interval options (e.g.)
        min: 0,
        max: 99,
      };
      break;
    case 'email':
      mask = /^[a-zA-Z0-9|\s|@|\\.|\-|\_]+$/;
      break;
    case 'phone':
      mask = '(00) 00000-0000';
      break;
    case 'date':
      mask = '00/00/0000';
      extra = {
        lazy: false,
        overwrite: true,
        validate: (value, masked) => {
          const errors = [];

          if (value.match(/^\d\d\/\d\d\/\d\d\d\d$/) != null) {
            const currentDate = moment(value, 'DD-MM-YYYY');
            if (!currentDate.isValid()) errors.push('invalid');
            // if(!currentDate.isBetween('1900-01-01', '1900-01-01')) errors.push('out_of_range');
          }

          return errors.length === 0;
        },
      };
      break;
    case 'monthYear':
      mask = '00/0000';
      extra = {
        lazy: false,
        overwrite: true,
        validate: (value, masked) => {
          const errors = [];

          if (value.match(/^\d\d\/\d\d\d\d$/) != null) {
            const currentDate = moment('01/' + value, 'DD-MM-YYYY');
            if (!currentDate.isValid()) errors.push('invalid');
            if (!currentDate.isBetween(moment(), '2100-01-01', 'year'))
              errors.push('out_of_range');
          }

          return errors.length === 0;
        },
      };
      break;
    case 'cardNumber':
      mask = '0000 0000 0000 0000';
      break;
    case 'zipCode':
      mask = '00000-000';
      break;
    case "document":
      extra = {
        mask: [{ mask: "000.000.000-00" }, { mask: "00.000.000/0000-00" }],
        dispatch: (appended, dynamicMasked) => {
          const document = (dynamicMasked.value + appended).replace(/\D/g, "");
          if (document.length > 11) {
            return dynamicMasked.compiledMasks[1];
          }
          return dynamicMasked.compiledMasks[0];
        },
      };
    default:
      break;
  }

  return { mask, extra };
}

export function TextMaskCPF(props) {
  return <TextMaskCustom {...props} mask="cpf" />;
}
export function TextMaskPhone(props) {
  return <TextMaskCustom {...props} mask="phone" />;
}

export function TextMaskDate(props) {
  return <TextMaskCustom {...props} mask="date" />;
}

export function TextMaskZipCode(props) {
  return <TextMaskCustom {...props} mask="zipCode" />;
}

export function TextMaskDocument(props) {
  return <TextMaskCustom {...props} mask="document" />;
}

export function TextMaskCurrency(props) {
  return <TextMaskCustom {...props} mask="currency" />;
}

export function legacyMaskCPF(elementRef) {
  const { mask, extra = {} } = routeMask('cpf');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskPhone(elementRef) {
  const { mask, extra = {} } = routeMask('phone');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskMonthYear(elementRef) {
  const { mask, extra = {} } = routeMask('monthYear');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskDate(elementRef) {
  const { mask, extra = {} } = routeMask('date');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyCardNumber(elementRef) {
  const { mask, extra = {} } = routeMask('cardNumber');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskZipCode(elementRef) {
  const { mask, extra = {} } = routeMask('zipCode');

  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskRegex(elementRef, regex) {
  return IMask(elementRef.current, { mask: regex });
}

export function legacyMaskEmail(elementRef) {
  const { mask, extra = {} } = routeMask('email');
  return IMask(elementRef.current, { mask, ...extra });
}

export function legacyMaskCurrency(elementRef, options = {}) {
  let { mask = Number, extra = {} } = routeMask('currency');
  return IMask(elementRef.current, { mask, ...extra, ...options });
}

export function legacyMaskNumber(elementRef, options = {}) {
  let { mask = Number, extra = {} } = routeMask('number');
  return IMask(elementRef.current, { mask, ...extra, ...options });
}
