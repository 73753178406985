import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassIcon from '@material-ui/icons/HourglassEmpty';
import axios from 'axios';
import CampaignSkeleton from '../../components/CampaignSkeleton';
import Campaign from '../../components/Campaign';
import OrderDetailsSkeleton from './OrderDetailsSkeleton';
import QRCodeSkeleton from './QRCodeSkeleton';
import OrderDetails from './OrderDetails';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import OrderQRCode from './OrderQRCode';
import { UserContext } from '../../InnerApp';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  mainHeader: {
    textAlign: 'left'
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  subtitle: {
    fontWeight: 400,
    color: '#FFE005',
    fontSize: '120%',
    textAlign: 'center'
  },
  whatsappLink: {
    borderRadius: '30px',
    padding: '7px 20px',
    marginBottom: '20px',
    display: 'inline-block',
    backgroundColor: '#2d8f4a',
    color: 'white',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: '100%'
  },
  priceTag: {
    fontWeight: 600,
    color: 'white',
    '& span': {
      backgroundColor: '#FFE005',
      borderRadius: '20px',
      fontWeight: 400,
      color: 'black',
      fontSize: '120%',
      padding: '5px 10px',
    }
  },
  social: {
    marginTop: '30px',
    textAlign: 'left',
    color: 'white',
    '& span': {
      color: '#FFE005',
      fontWeight: 600
    }
  },
  myTickets: {
    marginTop: '20px',
    padding: '10px 20px',
    textAlign: 'center',
    borderRadius: '15px',
    border: '1px solid #FFE105',
    backgroundColor: '#2d2d2d',
    color: 'white',
    '& img': {
      width: '20px',
      position: 'relative',
      top: '3px'
    }
  },
  description: {
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    padding: '20px',
    color: 'white',
    textAlign: 'left',
    overflow: 'hidden',
    '& > div': {
      overflowY: 'scroll',
      maxHeight: '300px'
    }
  },
  statusIcon: {
    marginRight: '10px'
  }
}));

export default function OrderPage({ currentUser, global_schema, order = null }) {
  const classes = useStyles();
  let { orderId } = useParams();
  const [loading, setLoading] = useState(order == null);
  const [currentOrder, setCurrentOrder] = useState(order);
  const [fetchOrderDelay, setFetchOrderDelay] = useState(1000);
  const unpaidOrder = useRef(false);
  const { user, setCurrentUser } = useContext(UserContext);
  const [whatsappGroupVisible, setWhatsappGroupVisible] = useState(false);


  async function fetchCurrentUser() {
    const response = await axios.get('/api/v1/customers/current');
    setCurrentUser(response.data);
  }

  function showWhatsappLink() {
    if (global_schema.whatsapp_link && global_schema.whatsapp_link != '') {
      setWhatsappGroupVisible(true);
    }
  }

  function fetchOrder() {
    axios.get(`/api/v1/purchase_orders/${orderId}.json`)
      .then((response) => {
        setCurrentOrder(response.data);
        if (response.data.status != 'completed') {
          unpaidOrder.current = true
        }
        if (response.data.status == 'created') {
          setTimeout(() => {
            fetchOrder();
          }, fetchOrderDelay);
          setFetchOrderDelay(fetchOrderDelay * 2);
        } else if (response.data.status == 'waiting_payment') {
          setTimeout(() => {
            fetchOrder();
          }, 12000);
        } else if (response.data.status == 'paid') {
          setTimeout(() => {
            fetchOrder();
          }, 20000);
          showWhatsappLink();
        } else if (response.data.status == 'completed') {
          showWhatsappLink();
          if (unpaidOrder.current && typeof dataLayer !== 'undefined') {
            dataLayer.push({'event': 'compraConfirmada'});
          }
          fetchCurrentUser();
        }
      })
      .catch((error) => {
        setCurrentOrder(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (order == null) {
      setLoading(true);
      fetchOrder();
    }
  }, []);

  function statusIcon() {
    if (currentOrder == null)
      return null;
    if (['paid', 'completed'].includes(currentOrder.status))
      return <CheckCircleIcon style={{fill: "#FFE005"}} className={classes.statusIcon} />
    if (['paid_after_time_limit', 'expired', 'error', 'canceled'].includes(currentOrder.status))
      return <ErrorIcon style={{fill: "red"}} className={classes.statusIcon} />
    if (['created', 'waiting_payment'].includes(currentOrder.status))
      return <HourglassIcon style={{fill: "orange"}} className={classes.statusIcon} />
  }

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div className={classes.main}>
          <div className={classes.mainHeader}>
            <Typography variant="h5" className={classes.title}>
              {loading ? "Carregando..." :
                <>
                  {statusIcon()}
                  {currentOrder?.user_status}
                </>
              }
            </Typography>
            {!loading && <div className={classes.subtitle}>{currentOrder?.user_status_message}</div>}
            <br />
          </div>
          {loading ? <CampaignSkeleton /> : <Campaign campaign={currentOrder?.campaign} type="list" />}
          {loading ? <QRCodeSkeleton /> : <OrderQRCode order={currentOrder} />}
          {whatsappGroupVisible && <a href={global_schema.whatsapp_link} target="_blank" className={classes.whatsappLink}>Entre no nosso grupo do TELEGRAM</a>}
          {loading ? <OrderDetailsSkeleton /> : <OrderDetails order={currentOrder} />}
          <div className={classes.orders}>
            {!loading && currentOrder == null && (
              <Typography variant="body1">
                Pedido inválido
                <br/><br/><br/><br/>
              </Typography>
            )}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  )
}