import React, { useState, useEffect } from 'react';
import { TextField, Typography, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import GenericForm from '../components/Generic/GenericForm';
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  warnForm: {
    marginBottom: '15px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  paper: {
    marginTop: '15%',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  }
}));

export default function ChangePassword(props) {
  const classes = useStyles();

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPasswords();
  }, []);

  function accountRemove(e) {
    setLoading(true)
    e.preventDefault();
    document.activeElement.blur();

    const formData = new FormData();
    formData.append("customer[password]", newPassword);

    axios
      .put(`/api/v1/customers/account_remove`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        setErrors('');
        toast.success(res.data.message);
        setTimeout(() => {
          setLoading(false)
          window.location.href = "/";
        }, 5000);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.status == 422) {
          setErrors(error.response.data);
        } else {
          toast.error(`Erro ao processar solicitação.`);
        }
      })
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  function checkPasswords() {
    return newPassword === ""
  }

  return (
    <div className={classes.paper}>
      <GenericForm
        title="Remover conta"
        loadingButtonLabel="REMOVER"
        loading={loading}
        disabled={checkPasswords()}
        onSubmit={accountRemove}
        backButton
      >
        <Typography className={classes.warnForm}>
          ATENÇÃO
          <br /><br />
          ⚠️ Após confirmar a remoção da conta, os dados não serão mais acessados e o valor disponível para saque será perdido. ⚠️
        </Typography>
        <TextField
          name="new-password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Senha atual"
          value={newPassword}
          error={errors["password"] ? true : false}
          type={showPassword ? 'text' : 'password'}
          helperText={errors["password"] ? errors["password"] : ""}
          onChange={(e) => setNewPassword(e.target.value)}
          autoComplete="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton tabIndex={-1} onClick={togglePasswordVisibility} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </GenericForm>
    </div>
  );
}