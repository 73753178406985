import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    borderRadius: '20px',
    backgroundColor: '#18181B',
    border: '1px solid #B4B2B2',
    textAlign: 'left',
    '& span': {
      margin: '10px',
    }
  },
}));

export default function QRCodeSkeleton() {
  const classes = useStyles();

  return (
    <div className={classes.skeleton}>
      <Skeleton style={{margin: 'auto'}} variant="rect" width="300" height={300} />
    </div>
  );
};
