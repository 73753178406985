import React, { useEffect, useState } from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ReactCodeInput from "react-verification-code-input";
import LoadingButton from '../../components/LoadingButton'
import { TextMaskPhone } from '../../components/TextMaskCustom';

const useStyles = makeStyles((theme) => ({
  newAccount: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    alignSelf: 'start'
  },
  newAccountFooter: {
    marginTop: '20px',
    alignSelf: 'start',
    fontSize: '14px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    borderRadius: '31px',
    border: '1px solid #FFE105',
    padding: '32px',
    backgroundColor: '#18181B',
  },
  sendSmsBtn: {
    fontSize: '12px',
    padding: '17px 5px',
    marginTop: '9px'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(5),
  },
  codeInput: {
    width: '325px !important',
    '& .styles_react-code-input__CRulA > input': {
      color: '#fff',
      backgroundColor: 'transparent',
      border: '1px solid #FFE105 !important',
      fontFamily: 'Poppins, sans-serif',
      margin: '8px',
      borderRadius: '0px',
      "@media(max-width: 425px)": {
        margin: '0px',
      },
    },
    '.styles_react-code-input__CRulA > input:focus': {
      border: '1px solid #FFE105',
      fontFamily: 'Poppins, sans-serif',
      caretColor: '#fff'
    },
    "@media(max-width: 425px)": {
      width: '232px !important',
    }
  },
  smsDiv: {
    display: 'none'
  }
}));

export default function SmsConfirmation() {
  const classes = useStyles();
  const [user, setUser] = useState({ unconfirmed_phone: '', token: '' });
  const [isDisabled, setDisabled] = useState(true);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);
  const [confirmarLoading, setConfirmarLoading] = useState(false);
  const [sendSmsLabel, setSendSmsLabel] = useState('ENVIAR')
  const [errors, setErrors] = useState({});
  
  function completePhoneRegistration(event) {
    event.preventDefault();

    setErrors({});
    setConfirmarLoading(true);
    axios
      .post('/api/v1/phone/verify', { token: user.token })
      .then(function (response) {
        window.location.href = '/site';
      })
      .catch(function ({ response }) {
        if (response) {
          setErrors(response.data);
        }
      })
      .then(function () {
        setConfirmarLoading(false);
      });
  };

  function handleSubmit(value){
    if (user && user.unconfirmed_phone && value.length == 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  function sendSms() {
    setErrors({});
    let unconfirmed_phone = user.unconfirmed_phone
    if(unconfirmed_phone){
      setSendSmsLoading(true);
      axios
        .post('/api/v1/phone', { customer: { unconfirmed_phone } })
        .then(function (response) {
          document.getElementById("smsDiv").style.display = 'block';
          setTimeout(function () {
            setSendSmsLoading(false);
          }, 25000);
        })
        .catch(function ({ response }) {
          if (response?.status == 422) {
            setErrors(response.data);
            document.getElementById("smsDiv").style.display = 'none';
          }
          setSendSmsLoading(false);
        });
    } else {
      setErrors({ ...errors, unconfirmed_phone: "Telefone inválido!" });
      document.getElementById("smsDiv").style.display = 'none';
    }
  }

  // lib do token seta o cursor para o campo, o useEffect tras para o primeiro campo
  useEffect(() => {
    document.getElementById('unconfirmed_phone').focus();
    document.getElementById("submitButton").disabled = true;
  }, []);

  return (
    <div className={classes.paper}>
      <Typography className={classes.newAccount} component="h1" variant="h5">
        Confirme seu telefone
      </Typography>
      <Typography className={classes.newAccountFooter}>
        É necessário confirmar o Telefone antes de prosseguir
      </Typography>
      <form className={classes.form} onSubmit={completePhoneRegistration} noValidate>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <TextField
            required={true}
            key={'unconfirmed_phone'}
            id={'unconfirmed_phone'}
            name={'unconfirmed_phone'}
            label={'Telefone'}
            fullWidth={true}
            autoComplete={'unconfirmed_phone'}
            autoFocus={true}
            value={user['unconfirmed_phone']}
            error={!!errors.unconfirmed_phone}
            type={'phone'}
            helperText={errors['unconfirmed_phone'] ? errors['unconfirmed_phone'] : ''}
            onChange={(e) => setUser({ ...user, ['unconfirmed_phone']: e.target.value })}
            InputProps={{
              inputComponent: TextMaskPhone,
            }}
          />
          <LoadingButton
            onClick={sendSms}
            fullWidth
            loading={sendSmsLoading}
            variant="contained"
            color="primary"
            className={classes.sendSmsBtn}
          >
            {sendSmsLabel}
          </LoadingButton>
        </div>
        <div className={classes.smsDiv} id="smsDiv">
          <Typography style={{ fontSize: '14px', padding: '20px 0px' }}>
            Digite o código de de 4 dígitos enviado para o seu número:
          </Typography>
          <div style={{ textAlign: '-webkit-center' }}>
            <ReactCodeInput className={classes.codeInput} onChange={e => { setUser({ ...user, ['token']: e }); handleSubmit(e) }} type="number" fields={4} />
            <span style={{ color: '#f44336', fontSize: '0.75rem' }}>{errors.token}</span>
          </div>
        </div>
        <LoadingButton
          type="submit"
          disabled={isDisabled}
          fullWidth
          loading={confirmarLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          id="submitButton"
        >
          CONTINUAR
        </LoadingButton>
      </form>
    </div>
  );
}
