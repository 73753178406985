import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Campaigns from "./Campaigns";
import Winners from "./Winners";
import SmallFaq from "../SmallFaq";
import axios from "axios";
import ResponsiveContainer from "../../components/ResponsiveContainer";

export default function Home({ currentUser, influencer, isMobile }) {
  const [campaigns, setCampaigns] = useState([]);
  const [loadingCampaigns, setLoadingCampaigns] = useState(true);

  const [winners, setWinners] = useState([]);
  const [loadingWinners, setLoadingWinners] = useState(true);


  useEffect(() => {
    fetchCampaigns();
    fetchWinners();
  }, []);

  async function fetchCampaigns() {
    try {
      const response = await axios.get(`/api/v1/campaigns/${influencer.username}/home.json`);
      setCampaigns(response.data || []);
    } catch (error) {
      setCampaigns([]);
    } finally {
      setLoadingCampaigns(false);
    }
  }

  async function fetchWinners() {
    try {
      const response = await axios.get(`/api/v1/campaigns/${influencer.username}/drawn_tickets.json`);
      setWinners(response.data || []);
    } catch (error) {
      setWinners([]);
    } finally {
      setLoadingWinners(false);
    }
  }

  return (
    <ResponsiveContainer>
      <Campaigns campaigns={campaigns} loading={loadingCampaigns} influencer={influencer} />
      <SmallFaq />
      <Winners winners={winners} loading={loadingWinners} influencer={influencer} />
    </ResponsiveContainer>
  );
}
