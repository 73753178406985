import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import GenericForm from '../components/Generic/GenericForm';
import GenericSmsConfirmation from '../components/Generic/GenericSmsConfirmation';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from "react-toastify";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ChangePhone() {
  const classes = useStyles();

  const [user, setUser] = useState({ unconfirmed_phone: '', token: '' });
  const [isDisabled, setDisabled] = useState(true);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);
  const [confirmarLoading, setConfirmarLoading] = useState(false);
  const [sendSmsLabel, setSendSmsLabel] = useState('ENVIAR')
  const [errors, setErrors] = useState({});

  function handleSubmit(value){
    if (user && user.unconfirmed_phone && value.length == 4) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  function completePhoneRegistration(event) {
    event.preventDefault();

    setErrors({});
    setConfirmarLoading(true);
    axios
      .post('/api/v1/phone/verify', { token: user.token })
      .then(function (response) {
        toast.success('Telefone alterado com sucesso!');
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch(function ({ response }) {
        if (response) {
          setErrors(response.data);
        }
      })
      .then(function () {
        setConfirmarLoading(false);
      });
  };

  function sendSms() {
    setErrors({});
    let unconfirmed_phone = user.unconfirmed_phone
    setSendSmsLoading(true);
    axios
      .put('/api/v1/phone/update_phone', { customer: { unconfirmed_phone } })
      .then(function (response) {
        document.getElementById("smsDiv").style.display = 'block';
        setTimeout(function () {
          setSendSmsLoading(false);
        }, 25000);
      })
      .catch(function ({ response }) {
        if (response?.status == 422) {
          setErrors(response.data);
          document.getElementById("smsDiv").style.display = 'none';
        }
        setSendSmsLoading(false);
      });
  }

  // lib do token seta o cursor para o campo, o useEffect tras para o primeiro campo
  useEffect(() => {
    document.getElementById('unconfirmed_phone').focus();
    document.getElementById("submitButton").disabled = true;
  }, []);

  return (
    <div className={classes.paper}>
      <GenericForm
        title="Alterar telefone"
        description="É necessário confirmar o novo Telefone."
        loadingButtonLabel="CONTINUAR"
        disabled={isDisabled}
        loading={confirmarLoading}
        onSubmit={completePhoneRegistration}
        backButton
      >
        <GenericSmsConfirmation
          user={{ get: user, set: setUser }}
          sendSmsLoading={{ get: sendSmsLoading, set: setSendSmsLoading }}
          confirmarLoading={{ get: confirmarLoading, set: setConfirmarLoading }}
          sendSmsLabel={{ get: sendSmsLabel, set: setSendSmsLabel }}
          errors={{ get: errors, set: setErrors }}
          handleReactCodeInput={handleSubmit}
          handleBtnSendSms={sendSms}
        />
      </GenericForm>
    </div>
  );
}