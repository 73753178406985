import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: '#18181B',
    borderRadius: '20px 20px 0 0',
    padding: '20px',
    marginTop: '20px',
    padding: theme.spacing(3),
    minWidth: "600px",
    maxWidth: "768px",
    "@media(max-width: 1024px)": {
      minWidth: "500px",
      maxWidth: "700px",
    },
    "@media(max-width: 768px)": {
      padding: theme.spacing(1),
      minWidth: "350px",
      maxWidth: "500px",
    },
    "@media(max-width: 500px)": {
      padding: theme.spacing(2),
      minWidth: "100%"
    },
  },
}));

export default function ResponsiveContainer({ children }) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      {children}
    </div>
  );
}
