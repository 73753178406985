import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GenericForm from '../components/Generic/GenericForm';
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkPasswords();
  }, []);

  function changePassword(e){
    setLoading(true)
    e.preventDefault();
    document.activeElement.blur();
    
    const formData = new FormData();
    if (!props?.user?.fast_account)
      formData.append("customer[current_password]", currentPassword);
    formData.append("customer[password]", newPassword);
    formData.append("customer[password_confirmation]", passwordConfirmation);

    axios
      .put(`/api/v1/customers/update_password`, formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        setErrors('');
        toast.success(res.data.message);
        setTimeout(() => {
          setLoading(false)
          window.location.href = "/";
        }, 5000);
      })
      .catch(function (error) {
        setLoading(false);
        if (error.response.status == 422){
          setErrors(error.response.data);
        } else {
          toast.error(`Erro ao processar solicitação.`);
        }
      })
  }

  function checkPasswords() {
    return (!props?.user?.fast_account && currentPassword === "") ||
      newPassword === "" ||
      passwordConfirmation === ""
  }
  
  return (
    <div className={classes.paper}>
      <GenericForm
        title={props?.user?.fast_account ? "Definir senha" : "Alterar senha"}
        loadingButtonLabel="ALTERAR"
        loading={loading}
        disabled={checkPasswords()}
        onSubmit={changePassword}
        backButton
      >
        {!props?.user?.fast_account &&
        <TextField
          name="current-password"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Senha atual"
          value={currentPassword}
          error={errors["current_password"] ? true : false}
          helperText={
            errors["current_password"] ? errors["current_password"] : ""
          }
          onChange={(e) => setCurrentPassword(e.target.value)}
          autoComplete="password"
          autoFocus
        />}
        <TextField
          name="new-password"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Nova senha"
          value={newPassword}
          error={errors["password"] ? true : false}
          helperText={errors["password"] ? errors["password"] : ""}
          onChange={(e) => setNewPassword(e.target.value)}
          autoComplete="password"
          autoFocus
        />
        <TextField
          name="password-confirmaiton"
          type="password"
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Confirmação da Senha"
          value={passwordConfirmation}
          error={errors["password_confirmation"] ? true : false}
          helperText={
            errors["password_confirmation"]
              ? errors["password_confirmation"]
              : ""
          }
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          autoComplete="password"
          autoFocus
        />
      </GenericForm>
    </div>
  );
}