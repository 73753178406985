import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../config/theme';
import Typography from '@material-ui/core/Typography';
import { numberAsCurrency } from '../utils/index';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '20px 0',
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    border: '1px solid #FFE005',
    padding: '10px 10px',
    minHeight: '100px',
    textAlign: 'left',
  },
  infoContainer: {
    color: 'white',
    padding: '10px',
  },
  created_at: {
    fontSize: '100%',
    float: 'right',
    marginTop: '10px'
  },
  value: {
    fontSize: '200%',
    fontWeight: 'bold'
  },
  status: {
    fontSize: '100%',
    float: 'right'
  },
  description: {
    fontSize: '90%'
  },
}));

export default function WithdrawalItem({ withdrawalRequest }) {
  const classes = useStyles();

  function colorForStatus(status) {
    if (status === 'approved') {
      return '#FFE005';
    } else if (status === 'created' || status === 'pending') {
      return 'white';
    } else if (status === 'rejected') {
      return '#FF4333';
    }
  }

  function textStyleForStatus(status) {
    if (status === 'approved') {
      return {color: '#FFE005'};
    } else if (status === 'created' || status === 'pending') {
      return {color: 'white'};
    } else if (status === 'rejected') {
      return {color: '#868686', textDecoration: 'line-through'};
    }
  }
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.infoContainer}>
          <span className={classes.created_at}>{moment(withdrawalRequest?.created_at).format('DD/MM/YY [às] HH:mm')}</span>
          <Typography variant="h6" className={classes.value} style={textStyleForStatus(withdrawalRequest?.status)}>
            {numberAsCurrency(withdrawalRequest?.value)}
          </Typography>
          <span className={classes.status} style={{color: colorForStatus(withdrawalRequest?.status)}}>{withdrawalRequest?.status_t}</span>
          <Typography variant="body1" className={classes.description}>
            {withdrawalRequest?.details}
          </Typography>
        </div>
      </div>
    </ThemeProvider>
  )
}