import React, { useState, useEffect } from 'react';
import { TextField, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GenericForm from '../components/Generic/GenericForm';
import { toast } from "react-toastify";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function ChangeEmail(props) {
  const classes = useStyles();
  const [email, setEmail] = useState(props?.user.email);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  function updateEmail(e){
    setLoading(true);
    e.preventDefault();
    document.activeElement.blur();

    const formData = new FormData();
    formData.append("customer[email]", email);

    axios
      .put(`/api/v1/customers/update_email`, formData,{
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res) {
        toast.success("Olhe sua caixa de e-mail para concluir o processo");
        setTimeout(() => {
          window.location.href = "/site/change_profile";
          setLoading(false);
          }, 5000);
        })
      .catch(function (error) {
        if (error.response?.status == 422) {
          setErrors(error.response.data);
        } else {
          toast.error(`Erro ao processar solicitação.`);
        }
        setLoading(false);
      })
  }

  return (
    <div className={classes.paper}>
      <GenericForm
        title="Alterar e-mail"
        loadingButtonLabel="ALTERAR"
        loading={loading}
        onSubmit={updateEmail}
        backButton
      >
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!errors?.email}
          helperText={errors["email"] ? errors["email"] : ""}
          autoComplete="email"
          autoFocus
        />
      </GenericForm>
    </div>
  );
}