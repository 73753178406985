import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Campaign from '../../components/Campaign';
import { useParams } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import LightningIcon from '../../../images/lightning.svg';
import CampaignSkeleton from '../../components/CampaignSkeleton';
import InstantIcon from '../../../images/rec-gf.png';
import CartIcon from '../../../images/cart.svg';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ResponsiveContainer from '../../components/ResponsiveContainer';
import SelectionBox from './SelectionBox';
import { numberAsCurrency } from '../../utils/index';
import ShortWinner from './ShortWinner';
import Swal from 'sweetalert2';
import Cookie from 'js-cookie';
import ExtraWinners from './ExtraWinners';
import InstantWinners from './InstantWinners';
import mascotIcon from '../../../images/mascot.png';

const useStyles = makeStyles((theme) => ({
  main: {
  },
  mainHeader: {
    textAlign: 'left'
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    '& small': {
      fontWeight: 400,
      color: '#FFE005',
      fontSize: '70%',
      marginLeft: '10px'
    }
  },
  priceTag: {
    fontWeight: 600,
    color: 'white',
    float: 'right',
    '& span': {
      backgroundColor: '#FFE005',
      borderRadius: '20px',
      fontWeight: 400,
      color: 'black',
      fontSize: '120%',
      padding: '5px 10px',
    },
    [theme.breakpoints.down('md')]: {
      float: 'none',
      display: 'block',
      textAlign: 'left',
      marginBottom: '10px'
    },
  },
  social: {
    float: 'left',
    marginTop: '4px',
    textAlign: 'left',
    color: 'white',
    '& span': {
      color: '#FFE005',
      fontWeight: 600
    }
  },
  myTickets: {
    marginTop: '20px',
    padding: '10px 20px',
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    borderRadius: '15px',
    border: '1px solid #FFE105',
    backgroundColor: '#2d2d2d',
    color: 'white',
    '& img': {
      width: '20px',
      position: 'relative',
      top: '3px'
    }
  },
  description: {
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    padding: '20px',
    color: 'white',
    textAlign: 'left',
    overflow: 'hidden',
    '& > div': {
      overflowY: 'scroll',
      maxHeight: '300px'
    }
  },
  instantBox: {
    marginTop: '20px',
    padding: '10px 20px',
    display: 'block',
    textDecoration: 'none',
    textAlign: 'center',
    borderRadius: '15px',
    border: '1px solid #FFE105',
    backgroundColor: '#2d2d2d',
    position: 'relative',
    color: 'white',
    '& img': {
      position: 'absolute',
      bottom: '3px',
      right: '4px',
      height: '120px',
      maxHeight: '95%'
    }
  },
  instantDescription: {
    fontWeight: 600,
    fontSize: '130%',
    textAlign: 'left',
    margin: '25px 150px 20px 20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '90%',
      margin: '10px 90px 10px 10px'
    }
  },
  instantHelper: {
    fontWeight: 400,
    fontSize: '100%',
    textAlign: 'left',
    margin: '10px 150px 10px 20px',
    [theme.breakpoints.down('md')]: {
      fontSize: '80%',
      margin: '5px 110px 5px 10px'
    }
  }
}));

export default function CampaignDetails({ currentUser, campaign = null, influencer }) {
  const classes = useStyles();
  const history = useHistory();
  let { campaignId } = useParams();
  const [loading, setLoading] = React.useState(campaign == null);
  const [purchaseLoading, setPurchaseLoading] = React.useState(false);
  const [currentCampaign, setCurrentCampaign] = React.useState(campaign);
  const [selectedTickets, setSelectedTickets] = React.useState(startTickets());
  const urlParams = new URLSearchParams(window.location.search);
  const ticketParameter = urlParams.get('tickets') ? parseInt(urlParams.get('tickets')) : null;
  const confirmParameter = urlParams.get('confirm') ? urlParams.get('confirm') == 'true' : false;
  
  function safeSetSelectedTickets(value) {
    if (value >= minTickets() && value <= maxTickets()) {
      setSelectedTickets(value);
    }
  }

  function maxTickets() {
    return currentCampaign?.max_amount == 0 ? 1000000 : currentCampaign?.max_amount;
  }

  function minTickets() {
    return currentCampaign?.min_amount || 1;
  }

  function startTickets() {
    return ticketParameter ? parseInt(ticketParameter) : minTickets();
  }

  function fastPurchaseCallback() {
    if (currentUser == null) {
      Cookie.set('redirect_to', `/${influencer.username}/site/campanhas/${currentCampaign.id}?tickets=${selectedTickets}&confirm=true`, { path: '/', expires: 1 });
      window.location.href = '/public/sign_in_or_up';
      return;
    }
    setPurchaseLoading(true);
    Swal.fire({
      title: 'Efetuando a compra',
      didOpen: () => {
        Swal.showLoading()
      }
    });
    axios.post('/api/v1/purchase_orders', {
      purchase_order: {
        campaign_id: currentCampaign.id,
        tickets_amount: selectedTickets
      }
    })
    .then((response) => {
      Swal.close();
      history.push(`/${influencer.username}/site/compras/${response.data.id}`);
    })
    .catch((error) => {
      debugger;
      const errorMessages = Object.values(error.response.data).join("<br/>");
      Swal.fire({
        title: 'Erro ao efetuar a compra',
        html: errorMessages,
        icon: 'error'
      });
    })
    .finally(() => {
      setPurchaseLoading(false);
    })
  }

  function purchaseCallback() {
    if (currentUser == null) {
      Cookie.set('redirect_to', `/${influencer.username}/site/campanhas/${currentCampaign.id}?tickets=${selectedTickets}`, { path: '/', expires: 1 });
      window.location.href = '/public/sign_up';
      return;
    }
    setPurchaseLoading(true);
    Swal.fire({
      title: 'Confirmar compra?',
      text: `Você está comprando ${selectedTickets} títulos por ${numberAsCurrency(selectedTickets * currentCampaign?.ticket_price)}.`,
      showDenyButton: true,
      confirmButtonText: 'Confirmar',
      denyButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Efetuando a compra',
          didOpen: () => {
            Swal.showLoading()
          }
        });
        axios.post('/api/v1/purchase_orders', {
          purchase_order: {
            campaign_id: currentCampaign.id,
            tickets_amount: selectedTickets
          }
        })
        .then((response) => {
          Swal.close();
          history.push(`/${influencer.username}/site/compras/${response.data.id}`);
        })
        .catch((error) => {
          var errorMessages;
          try {
            errorMessages = Object.values(error.response.data).join("<br/>");
          } catch (e) {
            errorMessages = "Erro desconhecido";
          }
          Swal.fire({
            title: 'Erro ao efetuar a compra',
            html: errorMessages,
            icon: 'error'
          });
        })
        .finally(() => {
          setPurchaseLoading(false);
        })
      } else {
        setPurchaseLoading(false);
      }
    })
  }

  function fetchCampaign() {
    axios.get(`/api/v1/campaigns/${campaignId}.json`)
      .then((response) => {
        setSelectedTickets(ticketParameter || response.data.initial_ticket_selection);
        setCurrentCampaign(response.data);
      })
      .catch((error) => {
        setCurrentCampaign(null);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function instantPrizeHelperText(campaign) {
    if (campaign.instant_prize_tickets > campaign.instant_winners.length) {
      return `Ainda faltam ${campaign.instant_prize_tickets - campaign.instant_winners.length} bilhetes premiados, compre agora!`
    } else {
      return `Confira os sortudos que levaram os bilhetes premiados abaixo!`
    }
  }

  React.useEffect(() => {
    if (campaign == null) {
      fetchCampaign();
    }
  }, []);

  React.useEffect(() => {
    if (currentCampaign && confirmParameter)
      purchaseCallback();
  }, [currentCampaign]);

  return (
    <ThemeProvider theme={theme}>
      <ResponsiveContainer>
        <div className={classes.main}>
          <div className={classes.mainHeader}>
            <Typography variant="h5" className={classes.title}>
              <img src={LightningIcon} height={30} className={classes.lightningIcon} />&nbsp;Campanhas
              <small>Escolha sua sorte</small>
            </Typography>
            <br />
          </div>
          {loading &&
            <CampaignSkeleton mode='first' />
          }
          <div className={classes.campaigns}>
            {!loading && currentCampaign && (
              <>
                <Campaign campaign={currentCampaign} currentUser={currentUser} mode='first' link={false} />
                <div>
                  <div className={classes.priceTag}>Por apenas <span>{numberAsCurrency(currentCampaign?.ticket_price)}</span></div>
                  <div className={classes.social}>
                    Sorteio loteria federal: <span>{currentCampaign && moment(currentCampaign?.draw_time).format('DD/MM/YY [a partir das] HH:mm')}</span>
                  </div>
                  <div style={{clear: 'both'}}></div>
                </div>
                {
                  currentCampaign && currentCampaign.instant_prize_tickets > 0 && currentCampaign["open?"] &&
                  <div class={classes.instantBox}>
                    <img src={InstantIcon} />
                    <div className={classes.instantDescription}>{currentCampaign?.instant_prize_description}</div>
                    <div className={classes.instantHelper}>{instantPrizeHelperText(currentCampaign)}</div>
                    <div style={{clear: 'both'}}></div>
                  </div>
                }
                {
                  currentCampaign && currentCampaign["open?"] &&
                  <SelectionBox selectedTickets={selectedTickets} purchaseCallback={purchaseCallback} fastPurchaseCallback={fastPurchaseCallback} setSelectedTickets={safeSetSelectedTickets} campaign={currentCampaign} loading={purchaseLoading}/>
                }
                {
                  currentCampaign?.drawn_customer && <ShortWinner campaign={currentCampaign} />
                }
                {
                  currentCampaign?.extra_winners && <ExtraWinners campaign={currentCampaign} />
                }
                {
                  currentCampaign && currentCampaign.instant_prize_tickets > 0 && <InstantWinners campaign={currentCampaign} />
                }
                <Link to={`/${influencer.username}/site/compras`} className={classes.myTickets}>
                  <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/cart.svg" />&nbsp;
                  Ver meus títulos
                </Link>
                <br/>
                <div className={classes.description}>
                  <div dangerouslySetInnerHTML={{ __html: currentCampaign?.description_body }}></div>
                </div>
              </>
            )}
            {!loading && currentCampaign == null && (
              <Typography variant="body1">
                Campanha inválida
                <br/><br/><br/><br/>
              </Typography>
            )}
          </div>
        </div>
      </ResponsiveContainer>
    </ThemeProvider>
  )
}