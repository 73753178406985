import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";

//
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paperScrollPaper": {
      border: "1px solid #FFE105",
      backgroundColor: "#18181B",
    },
    "& a": {
      textDecoration: "none",
      fontWeight: "bold",
      color: "#FFF",
    },
    '& .MuiButton-root': {
      color: '#18181B',
      backgroundColor: "#58DF46",
    },
  },
}));

export default function InstagramDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    props.setInstagramDialogClosed(true);
    setOpen(false);
  };

  const handleAgree = () => {
    window.open("https://www.instagram.com/esportesdasorte/", "_blank");
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.root}
    >

      <DialogTitle id="alert-dialog-title">
        {"Você já nos segue no instagram?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ color: "#FFF" }}
        >
          {`Para participar é desejável estar seguindo o Instagram `}
          <u>
            <a
              href="https://www.instagram.com/esportesdasorte/"
              target="_blank"
            >
              site.
            </a>
          </u>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Já sigo</Button>
        <Button onClick={handleAgree} autoFocus>
          Quero seguir
        </Button>
      </DialogActions>
    </Dialog>
  );
}
