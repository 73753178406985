import React from 'react';
import InfoIcon from '../../images/info.svg';

export default function InfoMessage(props) {
  return (
    <div style={{ 
      backgroundColor: '#2d2d2d',
      border: '1px solid #FFE005',
      borderRadius: '15px',
      padding: '10px 20px', 
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <img src={InfoIcon} style={{
        width: '20px',
        height: '20px',
        marginRight: '10px',
      }} />
      {props.children}
    </div>
  );
};
