import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { useHistory } from 'react-router-dom';

//
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    overflow: 'hidden',
    marginBottom: '20px',
    display: 'flex',
    cursor: 'pointer',
    minHeight: '132px'
  },
  imageContainer: {
    float: 'left',
    width: '15%',
    margin: '20px 10px 20px 20px',
    border: '1px solid #FFE005',
    backgroundColor: '#D9D9D9',
    minWidth: '100px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '100px',
    '& img': {
      flexShrink: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
  },
  details: {
    padding: '15px',
    textAlign: 'left',
    width: '100%'
  },
  title: {
    fontSize: '100%'
  },
  time: {
    color: '#BDBDBD',
    fontSize: '90%'
  },
  status: {
    color: 'white'
  },
  numbers: {
    display: 'flex',
    flexWrap: 'wrap',
    '& b': {
      color: 'black',
      fontWeight: '400',
      backgroundColor: '#E1C600',
      borderRadius: '5px',
      margin: '5px',
      padding: '1px 4px'
    },
  },
}));

export default function Order({order, currentUser}) {
  const classes = useStyles();
  const history = useHistory();
  moment.locale('pt-BR');

  function handleClick() {
    history.push(`/${order.campaign?.influencer_username}/site/compras/${order.id}`);
  }

  return (
    <div className={classes.root} onClick={handleClick}>
      <div className={classes.imageContainer}>
        <img src={order.campaign?.square_banner_url} />
      </div>
      <div className={classes.details}>
        <Typography className={classes.time} variant="body1">
          {moment(order.created_at).format("DD/MM/YYYY [às] HH:mm")}
        </Typography>
        <Typography className={classes.title} component="h5" variant="h5">
          {order.campaign?.title}
        </Typography>
        <Typography className={classes.status} variant="body1">
          {order.user_status}
        </Typography>
        <Typography className={classes.numbers} variant="body1">
          { 
            order?.status != 'canceled' &&
              order?.numbers?.map((number) => (
                <b>{number.toString().padStart(order.campaign.digits, '0')}</b>
              ))
          }
          {order?.numbers?.lenght > 0 && order?.numbers_count > 5 && <b>...</b>}
        </Typography>
      </div>
    </div>
  );
}
