import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Winner from '../../components/Winner';
import Typography from '@material-ui/core/Typography';
import InfoMessage from '../../components/InfoMessage';
import TrophyIcon from '../../../images/trophy.svg';

const useStyles = makeStyles((theme) => ({
  main: {
    minHeight: 400
  },
  mainHeader: {
    textAlign: 'left'
  },
  lightningIcon: {
    top: '7px',
    position: 'relative'
  },
  title: {
    fontWeight: 600,
    '& small': {
      fontWeight: 400,
      color: '#FFE005',
      fontSize: '70%',
      marginLeft: '10px'
    }
  }
}));

export default function Winners({ winners, loadingWinners, currentUser, influencer }) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.main}>
        <div className={classes.mainHeader}>
          <Typography variant="h5" className={classes.title}>
            <img src={TrophyIcon} height={30} className={classes.lightningIcon} />&nbsp;
            Ganhadores
            <small>
              Sortudos
            </small>
          </Typography>
          <br />
        </div>
        {loadingWinners && (
          <div>Carregando...</div>
        )}
        <div className={classes.campaigns}>
          {!loadingWinners && winners.length != 0 && (
            winners.map((winner, index) => (
              <Winner winner={winner} influencer={influencer} currentUser={currentUser} key={index} />
            )
          ))}
        </div>
        {!loadingWinners && winners.length == 0 && (
          <InfoMessage>Nenhum ganhador encontrado</InfoMessage>
        )}
      </div>
    </ThemeProvider>
  )
}