import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CopyIcon from '../../../images/copy.svg';
import { numberAsCurrency, copyToClipboard } from '../../utils/index';
import moment from 'moment';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    backgroundColor: '#18181B',
    border: '1px solid #B4B2B2',
    color: 'white',
    padding: '20px',
    margin: '20px 0',
    borderRadius: '20px',
    lineHeight: '25px',
    '& > img': {
      width: '100%',
      maxWidth: '300px',
      height: 'auto',
      margin: '20px 0'
    }
  },
  button: {
    border: '1px solid white',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    color: 'white',
    padding: '20px',
    cursor: 'pointer',
    '& img': {
      width: '20px',
      position: 'relative',
      top: '3px'
    },
    '& span': {
      position: 'relative',
      top: '-3px',
      marginLeft: '10px'
    }
  },
  info: {
    marginTop: '20px',
    fontWeight: 600,
    color: 'white',
    '& b': {
      backgroundColor: '#FFE005',
      borderRadius: '20px',
      fontWeight: 400,
      color: 'black',
      fontSize: '120%',
      padding: '5px 10px',
    }
  }
}));

export default function OrderQRCode({ order }) {
  if (order?.status !== 'waiting_payment' || moment(order?.created_at).isBefore(moment().subtract(20, 'minutes')))
    return null;

  const classes = useStyles();
  const [qrCodeDataUrl, setQRCodeDataUrl] = useState('');
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    // Generate QR code data URL for order
    QRCode.toDataURL(order.qr_code_text, (err, url) => {
      if (err) {
        console.error(err);
      } else {
        setQRCodeDataUrl(url);
      }
    });

    // Calculate time remaining until 10 minute limit
    const expirationTime = 20 * 60 * 1000;
    const createdTime = new Date(order.created_at).getTime();
    const endTime = createdTime + expirationTime;
    const intervalId = setInterval(() => {
      const remainingTime = endTime - Date.now();
      if (remainingTime <= 0) {
        clearInterval(intervalId);
        setTimeRemaining(0);
        window.location.reload();
      } else {
        setTimeRemaining(remainingTime);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [order]);

  const minutesRemaining = Math.floor(timeRemaining / (60 * 1000));
  const secondsRemaining = Math.floor((timeRemaining % (60 * 1000)) / 1000);

  function clipboardQRCode() {
    try {
      copyToClipboard(order.qr_code_text);
      toast.success('Código copiado');
    } catch (err) {
      toast.error('Erro ao copiar código. Selecione o código acima do botão e copie manualmente.');
    }
  }

  function selectText(event) {
    event.target.focus();
    event.target.setSelectionRange(0,9999);
    event.preventDefault();
  }

  return (
    <div className={classes.root}>
      <Typography variant="h6">
        <b>Escaneie o QRCode</b>
        <br/>
        para pagar com pix
      </Typography>
      <img src={qrCodeDataUrl} alt="Order QR code" />
      <br/>
      <input
        type="text"
        readOnly
        value={order?.qr_code_text}
        style={{ width: '100%' }}
        onClick={selectText}
      />
      <br/>
      <br/>
      <button className={classes.button} onClick={clipboardQRCode}>
        <img src="https://d2zdlj1ifv8u1d.cloudfront.net/images/copy.svg" />&nbsp;
        <span>Copiar código PIX</span>
      </button>
      <div className={classes.info}>
        Preço total: <b>{numberAsCurrency(order.total_value)}</b>
      </div>
      {timeRemaining > 0 && (
        <p>
          Tempo restante para pagamento: {minutesRemaining}m {secondsRemaining}s
        </p>
      )}
    </div>
  );
};
