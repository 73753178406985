import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { numberAsCurrency } from '../../utils/index';
import axios from 'axios';
import Lottie from "lottie-react";
import confettiAnimation from "../../../animations/confetti.json";
import TrophyIcon from "../../../images/trophy.svg";


const useStyles = makeStyles((theme) => ({
  main: {
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    padding: '20px',
    color: 'white',
    textAlign: 'left',
    marginBottom: '20px'
  },
  instantMain: {
    borderRadius: '20px',
    border: '2px solid #2d2d2d',
    backgroundColor: '#2d2d2d',
    padding: '20px',
    color: 'white',
    textAlign: 'center',
    marginBottom: '20px',
    position: 'relative',
    overflow: 'hidden',
  },
  instant: {
    backgroundColor: 'rgb(117, 236, 80)',
    color: 'rgb(18, 4, 120)',
    borderRadius: '3px',
    fontWeight: 600,
    padding: '1px 2px',
  },
  greenText: {
    color: '#FFE005',
  },
  confetti: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
  },
  instantTitle: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '150%',
    marginBottom: '10px'
  },
  instantValue: {
    textAlign: 'center',
    fontWeight: 500,
    margin: '20px 0'
  },
  instantNumber: {
    textAlign: 'center',
    fontWeight: 600,
    margin: '20px 0'
  }
}));

export default function OrderDetails({ currentUser, order }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [numbers, setNumbers] = React.useState([]);

  function fetchMoreNumbers() {
    setLoading(true);
    axios.get(`/api/v1/purchase_orders/${order.id}/numbers?offset=${numbers.length + order.numbers.length}`)
    .then(response => {
      setNumbers(numbers.concat(response.data));
    })
    .catch(error => {
      console.log(error);
    })
    .finally(() => {
      setLoading(false);
    })
  }
  if (!order)
    return null;

  function padn(n) {
    return n.toString().padStart(order.campaign.digits, '0')
  }

  function formatted_number(number, index) {
    if (order.instant_prizes.some(item => item.number === number))
      return <span title="Número premiado!" class={classes.instant}> {padn(number)}</span>;
    else
      return index != 0 ? `, ${padn(number)}` : padn(number);
  }
  
  return (
    <ThemeProvider theme={theme}>
      {
        order.instant_prizes.map((instant_prize, index) => (
          <div className={classes.instantMain}>
            <Typography variant="h3" className={classes.instantTitle}>
              Parabéns, você ganhou um <span className={classes.greenText}>prêmio instantâneo!</span>
            </Typography>
            <p style={{textAlign: 'center'}}>
              <img src={TrophyIcon} style={{height: '150px', position: 'relative', top: '3px'}} />
            </p>
            <Typography variant="h4" className={classes.instantNumber}>
              Número sorteado:<br/>
              <span className={classes.greenText}>{instant_prize.pretty_number}</span>
            </Typography>
            <Typography variant="h4" className={classes.instantValue}>
              Valor: {numberAsCurrency(instant_prize.instant_prize_value)}
            </Typography>
            <Lottie className={classes.confetti} animationData={confettiAnimation} />
          </div>
        ))
      }
      <div className={classes.main}>
        <Typography variant="h6">
          Detalhes da sua compra
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Comprador: </b>{order.customer.name}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>CPF: </b>{order.customer.cpf}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>E-mail: </b>{order.customer.email}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Telefone: </b>{order.customer.phone}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Data/horário: </b>{moment(order.created_at).format("DD/MM/YYYY [às] HH:mm")}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Situação: </b>{order.user_status}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Total: </b>{numberAsCurrency(order.total_value)}
        </Typography>
        <hr/>
        <Typography variant="body1">
          <b>Títulos comprados: </b>{order.numbers_count}
        </Typography>
        <hr/>
        {
          order.status != 'canceled' &&
          <Typography variant="body1">
            <b>Títulos: </b>
            {
              order.numbers.map((number, index) => (
                formatted_number(number, index)
              ))
            }
            {
              numbers.map((number, index) => (
                formatted_number(number, 1)
              ))
            }
            {['paid'].includes(order.status) && 'Títulos sendo emitidos, aguarde...'}
            {['paid'].includes(order.status) && moment(order.updated_at).isBefore(moment().subtract(3, 'minutes')) && 
              <><br/><b>A emissão dos títulos pode estar demorando um pouco mais que o normal, mas não se preocupe, suas chances de ganhar estão garantidas!</b></>}
            <br/>
            {['completed'].includes(order.status) && order.numbers_count > (numbers.length + order.numbers.length) &&
              <button onClick={fetchMoreNumbers} disabled={loading}>
                {loading ? 'Carregando...' : 'Carregar mais'}
              </button>
            }
          </Typography>
        }
      </div>
    </ThemeProvider>
  )
}