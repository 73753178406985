import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#18181B',
    },
    secondary: {
      main: '#FFE105',
    },
    listOpened: {
      main: '#1EC6A8',
    },
    listSelected: '#fc842b',
    background: {
      list: '#173963',
      nested: '#63504c',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif'
  },
  shape: {
    borderRadius: 20,
  },
  spacing: 0,
  overrides: {
    MuiButtonBase: {
      root: {
        color: '#fff !important'
      },
    },
    MuiTableHead: {
      root: {
        background: 'linear-gradient(0deg, #353535 0%, #212121 100%)'
      }
    },
    MuiTableCell: {
      root: {
        backgroundColor: 'transparent !important'
      },
      header: {
        backgroundColor: 'transparent !important'
      },
      body: {
        color: '#fff !important'
      }
    },
    MuiTableBody: {
      root: {
        backgroundColor: '#18181B',
        color: '#fff',
      }
    },
    MuiDataGrid: {
      root: {
        backgroundColor: '#18181B',
        color: '#fff',
      }
    },
    MuiCheckbox: {
      root: {
        color: '#f2f2f2',
      }
    },
    MuiSvgIcon: {
      root: {
        fill: '#f2f2f2'
      }
    },
    MuiMenuItem: {
      root: {
        // background: "linear-gradient(0deg, #353535 0%, #212121 100%)",
        justifyContent: 'center',
        color: '#fff',
        '@media(max-width: 600px)': {
          minHeight: 'auto'
        },
      }
    },
    MuiTypography: {
      root: {
        color: '#fff',
      }
    },
    MuiInputBase: {
      root: {
        '& .Mui-disabled': {
          backgroundColor: '#f2f2f2',
        }
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': { backgroundColor: '#FEDF02' }
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 14
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {},
        body: {
          fontSize: 16,
          fontFamily: 'Poppins, sans-serif',
          backgroundColor: '#2d2d2d',
          color: '#183B56'
        },
      },
    },
    MuiContainer: {
      maxWidthLg: {
        padding: 0,
        maxWidth: 1140,
        '@media(min-width: 1280px)': {
          maxWidth: 1140,
        },
      },
    },
    MuiButton: {
      root: {
        backgroundColor: '#2AB4C6',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#2ab4c68f',
        },
      },
      

      containedPrimary: {
        background: 'transparent linear-gradient(90deg, #585858 0%, #585858 0%, #212121 100%) 0% 0% no-repeat padding-box',
        borderRadius: '5px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 'bold',
        fontSize: '20px'
      },
      contained: {
        color: 'white',
      },
      text: {
        color: 'white',
        fontWeight: 'bold',
      },
      label: {
        padding: '0px 10px',
      },
      outlined: {
        backgroundColor: 'transparent',
        border: '1px solid #2ab4c68f',
        color: '#2AB4C6',
        '&:hover': {
          color: 'white',
        },
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          border: 'none',
        },
        '&::after': {
          border: 'none',
        },
        '&:hover:not(.Mui-disabled):before': {
          border: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: '#18181B',
        color: '#fff',
      },
      notchedOutline: {
        borderRadius: '5px',
        border: '1px solid #FFE105 !important',
      },
      adornedEnd: {
        paddingRight: 0,
        '& .MuiIconButton-root': {
          padding: '15px',
          borderRadius: '5px',
          border: 'none',
        },
        '& .MuiIconButton-edgeEnd': {
          marginRight: '0px'
        }
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#6A707E',
        backgroundColor: '#2d2d2d',
        boxShadow: 'none',
      },
    },
    MuiListItem: {
      root: {
        // color: '#FFF !important'
      },
      button: {
        '&:hover': {
          backgroundColor: '#FEDF02'
        }
      }
    },
    MuiPaper: {
      elevation8: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none'
      },
      elevation2: {
        backgroundColor: 'transparent !important',
        boxShadow: 'none'
      },
    },
  },
  MuiListItemText: {
    root: {
      color: '#fff'
    }
  },
  MuiPaper: {
    outlined: {
      padding: 20,
    },
  },
  MuiCheckbox: {
    color: '#fc842b !important',
    fill: '#fc842b !important',
    colorSecondary: {
      color: '#fc842b !important',
      fill: '#fc842b !important',
      '&$checked': {
        color: '#fc842b !important',
      },
    },
    colorPrimary: {
      color: '#fc842b !important',
      fill: '#fc842b !important',
      '&$checked': {
        color: '#fc842b !important',
      },
    },
  },
  props: {
    MuiInputLabel: {
      style: { color: '#464A53' },
    },
    MuiFormControl: {
      fullWidth: true,
    },
  
    MuiTab: {
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableRipple: true,
    },
    MuiCheckbox: {
      disableRipple: true,
    },
    MuiTextField: {
      variant: 'outlined',
      margin: 'normal',
      autoComplete: 'off',
      fullWidth: true,
      // defaultValue: '',
      InputLabelProps: {
        shrink: true,
        style: { color: 'white' },
      },
    },
    MuiPaper: {
      elevation: 8,
    },
    MuiCard: {
      elevation: 8,
    },
    MuiOutlinedInput: {},
  },
});
