import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme) => ({
  root: { 
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    overflow: 'hidden',
    marginBottom: '20px',
    display: 'flex',
    cursor: 'pointer'
  },
  rootFirst: {
    borderRadius: '20px',
    backgroundColor: '#2d2d2d',
    overflow: 'hidden',
    marginBottom: '20px',
  },
  imageContainerFirst: {
    height: '200px',
    overflow: 'hidden',
    position: 'relative',
    '& span': {
      width: '100%',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(0, -50%)',
      '-webkit-transform': 'translate(0, -50%)',
      '-moz-transform': 'translate(0, -50%)',
      '-ms-transform': 'translate(0, -50%)',
    },
  },
  imageContainer: {
    float: 'left',
    width: '20%',
    minWidth: '150px',
    borderRadius: '20px',
    overflow: 'hidden',
    position: 'relative',
    '& span': {
      'flex-shrink': '0',
      'width': '100%',
      'height': '100%',
      'object-fit': 'cover'
    },
  },
  details: {
    padding: '15px',
    textAlign: 'left',
    width: '100%',
    '& span': {
      marginBottom: '20px',
    }
  },
  detailsFirst: {
    padding: '15px',
    textAlign: 'left',
    width: '100%',
    '& span': {
      marginBottom: '10px',
    }
  },
  title: {
  },
  subtitle: {
    color: '#BDBDBD'
  },
  code: {
    '& b': {
      color: '#FFE005'
    },
    marginBottom: '5px',
  },
  status: {
    borderRadius: '10px',
    border: '1px solid #58DF46',
    padding: '0px 20px 2px 20px',
    color: 'white',
    fontWeight: 600,
  },
  deadline: {
    color: 'white',
    float: 'right',
    position: 'relative',
    top: '-3px',
    '& span': {
      position: 'relative',
      top: '2px',
    }
  },
}));

export default function CampaignSkeleton({ mode = 'list' }) {
  const classes = useStyles();
  
  return (
    <div className={mode == 'list' ? classes.root : classes.rootFirst}>
      <div className={mode == 'list' ? classes.imageContainer : classes.imageContainerFirst}>
        <Skeleton variant="rect" height={200} />
      </div>
      <div className={mode == 'list' ? classes.details : classes.detailsFirst}>
        <Skeleton variant="rect" height={20} />
        <Skeleton variant="rect" height={20} />
        <Skeleton variant="rect" height={20} />
        <Skeleton variant="rect" height={20} />
      </div>
    </div>
  );
}