import axiosClient from './axiosClient.js'
import axios from 'axios';
import { useLocation } from 'react-router-dom'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

/**
 * Authenticate the user
 * @param {Object} user - The user trying to sign in
 * @param {string} user.email - User email.
 * @param {string} user.password - User password.
 */
function signIn(user) {
  // Need to use default axios lib because i don't want 
  //  interceptors to work for this request in particular
  //  method because the way rails returns 401 when e-mail
  //  and password don't match.
  return axios.post('/customers/sign_in', { customer: { ...user } }, { headers: { 'Accept': 'application/json' } });
}

/**
 * Create an user account
 * @param {Object} user
 * @param {string} user.phone
 * @param {string} user.email
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function signUp(user) {
  let formData = new FormData();
  formData.append('customer[email]', user.email);
  formData.append('customer[phone]', user.phone);
  formData.append('customer[social_name]', user.social_name);
  formData.append('customer[password]', user.password);
  formData.append('customer[password_confirmation]', user.password_confirmation);
  return axiosClient.post('/customers', 
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

/**
 * Sign in or up
 * @param {Object} user
 * @param {string} user.phone
 * @param {string} user.cpf
 */
function signInOrUp(user) {
  let formData = new FormData();
  formData.append('customer[cpf]', user.cpf);
  formData.append('customer[phone]', user.phone);
  return axiosClient.post('/api/v1/customers/sign_in_or_up', 
    formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
}

/**
 * Request a password reset email
 * @param {Object} user
 * @param {string} user.email
 */
function requestPasswordReset(user) {
  return axiosClient.post('/customers/password', { customer: { ...user } });
}

/**
 * Reset user password
 * @param {Object} user
 * @param {string} user.password
 * @param {string} user.password_confirmation
 */
function resetPassword(user) {
  const query = new URLSearchParams(window.location.search);
  return axiosClient.put('/customers/password', { customer: { ...user, reset_password_token: query.get("reset_password_token")} });
}

const AuthorizationClient = {
  signIn, signUp, signInOrUp, requestPasswordReset, resetPassword
}

export default AuthorizationClient;
