import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { theme } from '../../../config/theme';
import ExtraWinner from './ExtraWinner';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
}));

export default function ExtraWinners({ campaign }) {
  const classes = useStyles();

  if (campaign?.extra_winners?.length == 0) return (<></>);
  
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Typography variant="h6" className={classes.title}>
          Outros ganhadores - prêmios secundários
        </Typography>
        {campaign.extra_winners.map((extra_winner, index) => <ExtraWinner key={index} index={index + 2} winner={extra_winner} />)}
      </div>
    </ThemeProvider>
  )
}