import classNameGenerator from './classNameGenerator'

export function classNames(styles) {
  function myClassNames(names) {
    let classes = names.split(' ').reduce((str, name) => {
      return str + `${styles[name]} `
    }, '')
    return classes.trim();
  }

  return myClassNames;
}

// Função pra formatar um número como R$, recebendo um número ou string, e retornando uma string
export function numberAsCurrency(number, show_currency = true) {
  if (number == null) {
    return '-';
  }
  return `${show_currency ? 'R$ ' : ''}${parseFloat(number).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}`;
}

export async function copyToClipboard(textToCopy) {
  // Navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    await navigator.clipboard.writeText(textToCopy);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // Move textarea out of the viewport so it's not visible
    textArea.style.position = "absolute";
    textArea.style.left = "-999999px";    
    document.body.prepend(textArea);
    textArea.select();
    try {
        document.execCommand('copy');
    } catch (error) {
        console.error(error);
    } finally {
        textArea.remove();
    }
  }
}