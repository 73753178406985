import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import LoadingButton from "../../components/LoadingButton";
import { useState } from "react";
import { toast } from "react-toastify";

//
const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SendConfirmationInstructions() {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(false);

  function submit(e) {
    e.preventDefault();

    setDisabled(true);
    axios
      .post("/api/v1/customers/send_confirmation_instructions")
      .then(function (response) {
        toast.success("Olhe sua caixa de e-mail para concluir o processo");
      })
      .catch(function ({ response }) {
        if (response?.status == 422) {
          toast.error(`Erro ao processar solicitação.`);
          setDisabled(true);
        }
      })
      .then((res) => {
        setDisabled(false);
      });
  }

  return (
    <>
      <LoadingButton
        onClick={submit}
        fullWidth
        loading={disabled}
        variant="contained"
        color="primary"
        className={classes.submit}
        id="submitButton"
      >
        VALIDAR E-MAIL
      </LoadingButton>
    </>
  );
}
